// header and nav

$mobile-menu-z-index: 200;

.mobile-banner {
  display: block;
  font-family: 'neue-haas-grotesk-display';
  font-size: 56px;
  line-height: 52px;
  position: fixed;
  height: var(--mobile-banner-height);
  top: 0;
  left: 0;
  width: 100%;
  background: var(--modal-bg);
  backdrop-filter: blur(8px);
  z-index: $mobile-menu-z-index + 1;
  padding: 16px 0;

  p {
    max-width: 330px;
  }

  @media (min-width: 837px) {
    display: none;
  }

  .btn {
    position: absolute;
    bottom: 16px;
    right: 16px;
    padding: 12px 24px;
  }

  .close-button {
    background: none;
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
  }
}

.th-header {
  @media (max-width: 836px) {
    &.banner-visible {
      top: var(--mobile-banner-height);
    }
  }


  --menu-text-color: #0f0f0f;
  --mobile-menu-bg-color: #fff;
  --mobile-menu-border-color: #e4e5e6;
  --submenu-link-hover-color: #eef4ff;
  --header-spot-bg-color: var(--submenu-link-hover-color);

  width: 100%;
  position: fixed;
  height: var(--header-height);
  top: 0;
  z-index: $mobile-menu-z-index;
  transition: transform 0.35s ease-out, background-color 0.25s linear;

  &.disable-sticky {
    // position: static;
    position: absolute;
  }

  &.mobile-open {
    background: var(--bg-color) !important;
    box-shadow: none !important;
    border-bottom: none;

    .mobile-menu-button {
      .bar {
        &:nth-child(1) {
          transform: rotate(-45deg) translateY(-3px);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(45deg) translateY(3px);
        }
      }
    }

    .mobile-menu {
      visibility: visible;
      opacity: 1;

      .mobile-menu__item {
        opacity: 1;
        transform: translateY(0);

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            transition-delay: #{0.07 * $i + 0.2}s;
          }
        }
      }
    }
  }

  &.transparent,
  &.dark {
    --mobile-menu-bg-color: #0f0f0f;
    --mobile-menu-border-color: rgb(228 229 230 / 20%);
    --menu-text-color: #fff;
    --submenu-link-hover-color: #272c32;
    --link-mask-color: #{#fff};

    .desktop-menu {
      .desktop-submenu {
        --submenu-border-color: #3c4249;
        --submenu-bg-color: #191c21;

        box-shadow: 0px 0px 15px -5px rgb(0 0 0 / 50%);
      }

      &__link {
        &:hover {
          color: inherit;
        }
      }
    }

    .desktop-submenu-link,
    .mobile-submenu-link {
      .submenu-icon {
      }
    }

    .th-logo {
    }
  }

  &.transparent {
    .desktop-menu {
      &__link {
        opacity: 0.85;

        &:hover {
          opacity: 1;
        }
      }

      &__button {
        opacity: 1;
      }
    }
  }

  &.headroom--pinned {
    transform: translateY(0%);
    &.headroom--not-top {
      .desktop-menu {
        padding: 3px 16px;
      }
      .btn {
        padding: 8px 16px;
      }
    }
  }

  &.headroom--unpinned {
    transform: translateY(-105%);
  }

  &.headroom--not-top {
    background: linear-gradient(45deg, black, rgba(33, 24, 102, 0.5));
    backdrop-filter: blur(8px);
    height: var(--header-height);
    position: fixed;
    box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);

    @supports not (backdrop-filter: blur(8px)) {
      background: var(--bg-color);
    }

    .th-logo {
      color: #fff;
    }
    .desktop-menu {
      color: #fff;
      &__button {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      &__link {
        color: #fff;
        .menu-icon {
          color: #fff;
        }
      }
    }
    .theme-toggle {
      color: #fff;
    }

    .mobile-menu-button {
      .bar {
        background: #fff;
      }
    }
  }

  .th-logo {
    flex-shrink: 0;
    height: 30px;
  }

  .header-spot {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    background: var(--header-spot-bg-color);

    &__text {
      font-size: 12px;
    }

    .th-btn {
      padding: 12px;
    }

    .link-mask-icon {
      align-self: flex-start;
    }

    .vimeo-player {
      &__inner {
        &::after {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}

.mobile-nav-wrap {
  .th-logo,
  .mobile-menu-button {
    position: relative;
    z-index: $mobile-menu-z-index + 1;
  }
}
.mobile-open {
  .mobile-nav-wrap {
    .th-logo,
    .mobile-menu-button,
    .theme-toggle {
      color: var(--text-color);
      .bar {
        background: var(--text-color);
      }
    }
  }
}

.desktop-nav-wrap {
  display: none;
}

@media screen and (min-width: 1140px) {
  .mobile-nav-wrap {
    display: none;
  }

  .desktop-nav-wrap {
    display: flex;
  }
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
}

.mobile-menu {
  padding: 0px 0 40px;
  position: absolute;
  top: var(--header-height);
  left: 0;
  right: 0;
  color: var(--text-color);
  background: var(--menu-mobile-bg);
  // CSS hack to take into consideration additional height on certain ios devices, vh kept as fallback as dvh wont work for all
  height: calc(100vh - var(--header-height) - var(--mobile-banner-height));
  height: calc(100dvh - var(--header-height) - var(--mobile-banner-height));
  font-size: 38px;
  font-weight: 500;
  font-family: 'neue-haas-grotesk-display';
  z-index: $mobile-menu-z-index;
  visibility: hidden;
  opacity: 0;
  transition: all 0.375s;
  overscroll-behavior: contain;
  overflow-y: auto;

  &__list,
  &__item {
    list-style: none;
    padding: 0;
  }

  &__item {
    margin: 0;
    opacity: 0;
    transform: translateY(-10px);
    transition: transform 0.5s ease, opacity 0.6s ease;
  }

  .mobile-menu-expander,
  &__link {
    padding: 7px 0;
    line-height: 41px;
    color: inherit;
  }

  &__link {
    display: block;
    text-decoration: none;
    font-weight: 400;
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  .header-spot {
    .th-btn {
      max-width: unset;
    }
  }

  .mobile-menu-content {
    font-family: 'Inter';
    font-size: 16px;
    fontweight: 400;
    margin-top: 40px;
  }

  .menu-icon {
    position: relative;
    top: 8px;
    left: 8px;
  }

}

.desktop-menu {
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-color);
  gap: 32px;
  border-radius: 56px;
  background: var(--menu-bg);
  padding: 13px 32px;
  font-size: 14px;

  &__item {
    position: relative;
    display: inline-block;
    text-align: center;
  }

  &__link {
    font-weight: 500;
    line-height: 30px;
    color: var(--nav-color);
    flex-shrink: 0;
    text-decoration: none;
    transition: opacity 0.25s ease;
    cursor: pointer;
    .menu-icon {
      color: var(--text-color);
      position: relative;
      top: 4px;
      left: 3px;
    }

    &:hover,
    &.active {
      color: var(--nav-color-active);
      .menu-icon {
        color: var(--nav-color-active);
      }
    }

    &::before {
      content: attr(data-title);
      display: block;
      font-weight: 600;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  &__button {
    @extend .th-btn;
    opacity: 1;
  }
}

.mobile-menu-button {
  width: 32px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  background: none;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  .bar {
    width: 32px;
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--text-color);
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transform-origin: right;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 9px;
      width: 23px;
      transform: translateX(9px);
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }
}
