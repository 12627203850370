@import '~@splidejs/splide/dist/css/splide-core.min.css';

.splide {
  .splide__arrow {
    @include sprite('splide-arrow');
    width: 72px;
    height: 11px;
    box-sizing: content-box;
    padding: 20px 0;
    cursor: pointer;

    &:hover {
      @include sprite(
        'splide-arrow',
        (
          'color': var(--primary-blue),
        )
      );
    }

    svg {
      display: none;
    }

    &--next {
      margin-left: 24px;
      transform: rotate(180deg);
    }
  }

  .splide__pagination {
    width: 100%;
    margin-top: 32px;

    &__page {
      box-sizing: content-box;
      width: 10px;
      height: 10px;
      margin: 0 4px;
      padding: 12px;
      background-color: var(--text-color);
      opacity: 0.3;
      background-clip: content-box;
      border-radius: 50%;
      cursor: pointer;

      &.is-active {
        opacity: 1;
      }
    }
  }
}
