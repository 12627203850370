@mixin default-font($fsm: 14px, $fsd: 16px, $mw: 1024px) {
  font-family: var(--default-font);
  font-weight: 300;
  font-size: $fsm;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  @media screen and (min-width: $mw) {
    font-size: $fsd;
  }
}

@mixin heading-font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + '.eot');
    src: url($font-file + '.eot?#iefix') format('embedded-opentype'),
      url($font-file + '.woff') format('woff'),
      url($font-file + '.woff2') format('woff2'),
      url($font-file + '.ttf') format('truetype'),
      url($font-file + '.svg#' + $font-family) format('svg');
    font-weight: 500;
  }
}

* {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
h1, h2, h3 {
  @include heading-font('neue-haas-grotesk-display', '/static/fonts/NeueHaasDisplayMedium');
  font-family: "neue-haas-grotesk-display";
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media (min-width: 837px) {
  .text-md-left {
    text-align: left;
  }

  .text-md-center {
    text-align: center;
  }

  .text-md-right {
    text-align: right;
  }
}

@media (min-width: 1140px) {
  .text-lg-left {
    text-align: left;
  }

  .text-lg-center {
    text-align: center;
  }

  .text-lg-right {
    text-align: right;
  }
}

// font size and weight

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-size-xs {
  font-size: 12px; /* 12px */
  line-height: 16px; /* 16px */
}

.font-size-s {
  font-size: 14px; /* 14px */
  line-height: 20px; /* 20px */
}

.font-size-m {
  font-size: 16px; /* 16px */
  line-height: 24px; /* 24px */
}

.font-size-lg {
  font-size: 18px; /* 18px */
  line-height: 28px; /* 28px */
}

.font-size-l {
  font-size: 20px; /* 20px */
  line-height: 28px; /* 28px */
}

.font-size-2xl {
  font-size: 24px; /* 24px */
  line-height: 32px; /* 32px */
}

.break-words {
  overflow-wrap: break-word;
}

.text-underline {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-branded {
  color: var(--primary-blue);
}



.page-heading {
  font-size: 72px;
  font-weight: 500;
  line-height: 74px;
  letter-spacing: -0.616px;

  &:has(+ .page-text),
  &:has(+ .section-text) {
    margin-bottom: 40px;
  }
}

.page-heading-small {
  font-size: 56px;
  font-weight: 500;
  line-height: 52px; /* 92.857% */
  letter-spacing: -0.616px;

  &:has(+ .page-text),
  &:has(+ .section-text) {
    margin-bottom: 40px;
  }
}

.page-text {
  font-size: 38px;
  font-weight: 500;
  line-height: 41px;
  letter-spacing: -0.44px;
}

.section-heading {
  font-size: 56px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: -0.616px;
  @media (min-width: 837px) {
    font-size: 72px;
    line-height: 74px;
  }
  &:has(+ .section-text) {
    margin-bottom: 24px;
  }

  &:not(:has(+ *)),
  &:has(+ div):not(:has(+ :not(div))) {
    margin-bottom: 48px;
  }
  &:has(+ .btn) {
    margin-bottom: 48px;
  }

  &.xl {
    font-size: 64px;
    line-height: 64px;
    @media (min-width: 1140px) {
      font-size: 114px;
      line-height: 114px;
    }
  }
}

.section-subtitle {
  font-size: 38px;
  font-weight: 500;
  line-height: 41px;
  letter-spacing: -0.418px;
}

.section-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.264px;
  margin-bottom: 24px;
}



// SCSS mixin for gradient text
@mixin gradient-text($from, $to, $angle) {
  background: linear-gradient($angle, $from, $to);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.gradient-text-gold-blue {
  background: linear-gradient(87deg, #ffae00 0%, #3241e7 61%, #2e3cd3 99%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.gradient-text-grey-white {
  @include gradient-text(var(--gradient-grey-first) var(--gradient-grey-position), var(--gradient-grey-last), var(--gradient-grey-angle));
}

.gradient-text-purple-indigo {
  background: linear-gradient(87deg, #A12BF0 0%, #3241e7 61%, #2e3cd3 99%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.dull-text {
  color: var(--text-color-dull);
}

.dull-text-menu {
  color: var(--menu-text-color-dull);
}
