.footer-wrap {
  padding-top: 200px;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 80px;
    left: -20vw;
    width: 140vw;
    height: 140vw;
    z-index: 1;
    border-radius: 50%;
    background: radial-gradient(138.32% 138.32% at 50% 109.46%, var(--bg-color) 60%, var(--gradient-colored-bg) 75%);
  }
  &::before {
    // blur
    content: "";
    position: absolute;
    top: 60px;
    left: -20vw;
    width: 140vw;
    height: 140vw;
    z-index: 0;
    border-radius: 50%;
    background: radial-gradient(138.32% 138.32% at 50% 109.46%, var(--bg-color) 60%, var(--gradient-colored-bg) 75%);
    filter: blur(30px);
  }
}

.contact-section {
  padding-bottom: 100px;
  font-size: 16px;
  background: transparent;
  .section-heading {
    max-width: 300px;
    margin: 0 auto 48px;
    @media (min-width: 837px) {
      max-width: 100%;
      margin: 0 auto;
      font-size: 56px;
      line-height: 58px;
    }
    @media (min-width: 1440px) {
      font-size: 72px;
      line-height: 74px;
      margin: 0 auto 16px;
    }
  }
  .container {
    z-index: 2;
    position: relative;
    @media (min-width: 837px) {
      max-width: 735px;
    }
    @media (min-width: 1440px) {
      max-width: 1100px;
    }
  }
  .katerina-outer {
    position: relative;
    max-width: 250px;
    margin: 0 auto 24px;
    @media (min-width: 837px) {
      margin-bottom: 0;
      max-width: 100%;
    }
    // Vertical lines
    &::before {
      content: '';
      position: absolute;
      opacity: 0.6;
      background: linear-gradient(180deg, rgba(20, 19, 21, 0) 0%, var(--text-color) 14%, var(--text-color) 80%, rgba(20, 19, 21, 0) 100%);
      width: 1px;
      height: 130%;
      left: 0;
      top: -12%;
    }
    &::after {
      content: '';
      position: absolute;
      opacity: 0.6;
      background: linear-gradient(180deg, rgba(20, 19, 21, 0) 0%, var(--text-color) 14%, var(--text-color) 80%, rgba(20, 19, 21, 0) 100%);
      width: 1px;
      height: 130%;
      right: 0;
      top: -12%;
    }
    .katerina-inner {
      position: relative;
      // Horizontal lines
      &::before {
        content: '';
        position: absolute;
        opacity: 0.6;
        background: linear-gradient(90deg, rgba(20, 19, 21, 0) 0%, var(--text-color) 5%, var(--text-color) 45%, rgba(20, 19, 21, 0) 100%);
        width: 140%;
        height: 1px;
        top: 0;
        left: -20%;
        @media (min-width: 837px) {
          left: -40%;
          width: 880px;
        }
        @media (min-width: 1440px) {
          width: 1200px;
        }
      }
      &::after {
        content: '';
        position: absolute;
        opacity: 0.6;
        background: linear-gradient(90deg, rgba(20, 19, 21, 0) 0%, var(--text-color) 5%, var(--text-color) 45%, rgba(20, 19, 21, 0) 100%);
        width: 140%;
        height: 1px;
        bottom: 0;
        left: -20%;
        @media (min-width: 837px) {
          left: -40%;
          width: 880px;
        }
        @media (min-width: 1440px) {
          width: 1200px;
        }
      }
    }
  }
  .btn {
    margin-top: 12px;
    @media (min-width: 1140px) {
      margin-top: 16px;
    }
    @media (min-width: 1440px) {
      margin-top: 64px;
    }
  }

  .contact-content {
    text-align: center;
    @media (min-width: 837px) {
      text-align: left;
      padding-left: 48px;
      padding-top: 24px;
    }
  }

}




.footer {
  font-size: 14px;
  background: transparent;
  color: var(--text-color);
  padding: 48px 0;
  position: relative;
  z-index: 5;

  %footer-link {
    text-decoration: none;
    font-weight: 300;
  }

  .footer-logo {
    display: block;
    width: 191px;
    height: 30px;
    flex-shrink: 0;
    margin: 0 auto;
    @media (min-width: 837px) {
      margin: 0;
    }
  }

  .footer-main {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 1140px) {
      justify-content: space-between;
      flex-direction: row;
    }

    &__about {
      p {
        line-height: 29px;
      }
    }

    &__nav {
      display: grid;
      grid-template-columns: 1fr;
      @media (min-width: 837px) {
        grid-template-columns: 2fr 1fr;
      }
      &__list {
        text-align: center;
        &:first-child {
          columns: 1;
          margin-top: 16px;
          @media (min-width: 837px) {
            columns: 2;
            margin-top: 0;
          }
        }
        &:last-child {
          .footer-main__nav__list__item:first-child {
            margin-top: 16px;
            @media (min-width: 837px) {
              margin-top: 0;
            }
          }
        }
        @media (min-width: 1140px) {
          text-align: left;
        }
        &__item {
          padding: 4px 0;
          &__link {
            padding: 4px 0;
            text-decoration: none;
            font-weight: 400;
            color: var(--text-color);
          }
        }
      }
    }

    &__misc {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @media (min-width: 837px) {
        align-items: flex-end;
        text-align: right;
        justify-content: flex-end;
        line-height: 27px;
      }
    }
  }

}
