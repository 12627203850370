%input {
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  outline: none;
  padding: 16px;
  width: 100%;
  background: transparent;
  color: var(--text-color);
  border: 1px solid var(--text-color);

  &:focus {
    box-shadow: 0px 0px 15px 0 rgba(0,0,0,.5);
  }

  &:invalid:not(:focus):not(:placeholder-shown) {
    border-color: #FF8F8F;
  }

  &::placeholder {
    color: #9b9b9b;
  }
}

.form {
  .htt-btn.submit {
    margin-top: 8px;
  }
}

.form-row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: space-between;
  grid-gap: 5px 16px;
  margin-bottom: 16px;
  position: relative;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @for $i from 1 through 10 {
    & > *:nth-child(#{$i}) {
      order: $i;

      @media (min-width: 1140px) {
        order: $i;
      }
    }
  }

  .form-input {
    &:nth-child(1) {
      order: 1;

      @media (min-width: 1140px) {
        order: 1;
      }
    }

    &:nth-child(2) {
      order: 3;

      @media (min-width: 1140px) {
        order: 2;
      }
    }
  }

  .form-error {
    &:nth-child(3) {
      order: 2;

      @media (min-width: 1140px) {
        order: 3;
      }
    }

    &:nth-child(4) {
      order: 4;

      @media (min-width: 1140px) {
        order: 4;
      }
    }
  }
}

.form-group {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  margin-bottom: 16px;
  position: relative;

  &.has-select {
    position: relative;

    &::after {
      @include sprite-mask('chevron-down');
      content: '';
      position: absolute;
      top: calc(24px + 5px + 25px);
      right: 5%;
      width: 12px;
      height: 8px;
      background: #fff;
      transform: translateY(-50%);
      z-index: -1;
    }
  }
}

.form-pw-container {
  position: relative;

  .view-password-toggle {
    top: 50%;
    transform: translateY(-50%);
  }
}

.form-input {
  @extend %input;
}

.form-textarea {
  @extend %input;

  height: 100%;
  width: 100%;
  resize: vertical;
}

.form-error {
  position: relative;
  z-index: -1000;
  opacity: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #FF8F8F;

  &.show {
    z-index: 1;
    opacity: 1;
    color: #FF8F8F;
  }
}

.form-main-error {
  opacity: 0;
  border: 1px solid #FF8F8F;
  background: transparent;
  padding: 8px 16px;
  border-radius: 4px;
  &.error {
    opacity: 1;
    color: #FF8F8F;
  }
}

.phone-select-wrapper {
  display: flex;

  .form-select {
    &__select {
      min-width: 14ch;
      max-width: 131px;
      height: 100%;

      &:not(:focus) {
        border-right: none;
      }

      &:focus + .form-input {
        border-left: none;
      }
    }
  }
}

.form-select {
  position: relative;

  &__select {
    @extend %input;
    appearance: none;
    width: 100%;

    padding-right: 36px;

    & > option {
      color: initial;
      font-weight: initial;
    }
  }

  &::after {
    content: '';
    margin-top: -6px;
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 16px;
    border-bottom: 2px solid var(--primary-blue);
    border-right: 2px solid var(--primary-blue);
    pointer-events: none;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
  }
}

.form-general-error.error,
.onboarding-step #createAccountErrMsg.error,
.onboarding-step #loginAccountErrMsg.error,
.onboarding-step #forgotPwErrMsg.error,
.affiliate-form #createAccountErrMsg.error,
#downloadErrMsg.error,
.contact-form #contactFormErrMsg.error {
  margin-bottom: 1rem;
  padding: 6px 10px;
  color: #f00;
  background: #ffe8e8;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.view-password-toggle.active {
  display: none;
}

.view-password-toggle {
  position: absolute;
  top: 20%;
  right: 15px;
  cursor: pointer;
  // Need this for the js-view-password-toggle event listener, to stop <use> element having the click listener
  & > * {
    pointer-events: none;
  }
}

.htt-form {
  position: relative;

  &__fieldset {
    transition: opacity 0.25s ease;
    position: relative;

    &.hidden {
      opacity: 0;
      z-index: -1;
    }
  }

  .form-success {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: opacity 1s ease;

    &.hidden {
      opacity: 0;
      z-index: -1;
    }

    &__icon {
      background-size: contain;
      width: 64px;
      height: 64px;
    }

    &__title,
    &__text {
      margin-top: 16px;
      text-align: center;
    }

    &__title {
      font-size: 18px;
      font-weight: 400;

      @media (min-width: 768px) {
        font-size: 24px;
      }
    }
  }
}

.nice-select {
  background: transparent;
  height: 52px;
  padding: 6px 18px;
  border: 1px solid var(--text-color);
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  float: none;
  .nice-select-dropdown {
    background: var(--modal-bg);
    border: 1px solid var(--text-color);
    width: 100%;
  }
  .option {
    &:hover,
    &.focus,
    &.selected.focus {
      background-color: var(--bg-color);

    }
  }
  &::after {
    height: 8px;
    width: 8px;
    top: calc(50% - 2px);
    right: 16px;
  }
}

select.nice-select {
  display: none;
}

// These are honeypot fields to trick spam bots, called it something similar to existing classes so it looks legit, but we will actually hide these
.contact-input-field {
  opacity: 0;
  position: absolute;
  z-index: -1;
  display: none;
}
