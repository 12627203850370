.vertical-centered {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile-mx-auto {
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1140px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.flex {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.col {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  @media (min-width: 837px) {
    &.md-row {
      flex-direction: row;
    }

    &.md-col {
      flex-direction: column;

      .flex-block-20,
      .flex-block-30,
      .flex-block-40,
      .flex-block-50,
      .flex-block-60,
      .flex-block-70,
      .flex-block-80 {
        @media (min-width: 837px) {
          max-width: 100%;
          flex-grow: 1;
        }
      }

      &.lg-row {
        @media (min-width: 1140px) {
          .flex-block-20 {
            max-width: 20%;
            flex-grow: 0.2;
          }

          .flex-block-30 {
            max-width: 30%;
            flex-grow: 0.3;
          }

          .flex-block-40 {
            max-width: 40%;
            flex-grow: 0.4;
          }

          .flex-block-50 {
            max-width: 50%;
            flex-grow: 0.5;
          }

          .flex-block-60 {
            max-width: 60%;
            flex-grow: 0.6;
          }

          .flex-block-70 {
            max-width: 70%;
            flex-grow: 0.7;
          }

          .flex-block-80 {
            max-width: 80%;
            flex-grow: 0.8;
          }
        }
      }
    }
  }

  @media (min-width: 1140px) {
    &.lg-row {
      flex-direction: row;
    }

    &.lg-col {
      flex-direction: column;
    }
  }

  &.mobile-reversed {
    @media (max-width: 767px) {
      flex-direction: column-reverse;

      .flex-block-20,
      .flex-block-30,
      .flex-block-40,
      .flex-block-50,
      .flex-block-60,
      .flex-block-70,
      .flex-block-80 {
        &:nth-child(n + 2) {
          margin-top: 0;
        }

        &:nth-child(1) {
          margin-top: 24px;

          &.order-1 {
            margin-top: 0;
          }
        }
      }
    }
  }
}

// Flexbox alignment utility classes
.justify-content-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.items-normal {
  align-items: normal;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-gap-8 {
  gap: var(--spacing-xxxs);
}

.flex-gap-16 {
  gap: var(--spacing-xxs);
}

.flex-gap-24 {
  gap: var(--spacing-xs);
}

.flex-gap-32 {
  gap: var(--spacing-sm);
}

.flex-gap-48 {
  gap: var(--spacing-lg);
}

@media (min-width: 768px) {
  .md-justify-content-center {
    justify-content: center;
  }

  .md-space-between {
    justify-content: space-between;
  }

  .flex-nowrap-md {
    flex-wrap: nowrap;
  }

  .md-align-end {
    align-items: flex-end;
  }
}

@media (min-width: 1140px) {
  .flex-nowrap-lg {
    flex-wrap: nowrap;
  }

  .lg-justify-start {
    justify-content: flex-start;
  }

  .lg-justify-content-center {
    justify-content: center;
  }

  .lg-justify-end {
    justify-content: flex-end;
  }

  .lg-align-end {
    align-items: flex-end;
  }
}

.mt-xs {
  margin-top: 8px;
}

.mb-xs {
  margin-bottom: 8px;
}

.mt-auto{
    margin-top: auto;
}
.mt-s {
  margin-top: 8px;

  @media (min-width: 1140px) {
    margin-top: var(--spacing-xxs);
  }
}

.mb-s {
  margin-bottom: 8px;

  @media (min-width: 1140px) {
    margin-bottom: var(--spacing-xxs);
  }
}

.mt-m {
  margin-top: 24px;

  @media (min-width: 1140px) {
    margin-top: 32px;
  }
}

.mb-m {
  margin-bottom: 24px;

  @media (min-width: 1140px) {
    margin-bottom: 32px;
  }
}

.mt-l {
  margin-top: 32px;

  @media (min-width: 1140px) {
    margin-top: 40px;
  }
}

.mb-l {
  margin-bottom: 32px;

  @media (min-width: 1140px) {
    margin-bottom: 40px;
  }
}

.ml-xs {
  margin-left: 8px;
}

.mr-xs {
  margin-right: 8px;
}

.ml-s {
  margin-left: 8px;

  @media (min-width: 1140px) {
    margin-left: var(--spacing-xxs);
  }
}

.mr-s {
  margin-right: 8px;

  @media (min-width: 1140px) {
    margin-right: var(--spacing-xxs);
  }
}

.ml-m {
  margin-left: 24px;

  @media (min-width: 1140px) {
    margin-left: 32px;
  }
}

.mr-m {
  margin-right: 24px;

  @media (min-width: 1140px) {
    margin-right: 32px;
  }
}

.ml-l {
  margin-left: 32px;

  @media (min-width: 1140px) {
    margin-left: 40px;
  }
}

.mr-l {
  margin-right: 32px;

  @media (min-width: 1140px) {
    margin-right: 40px;
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.hidden {
  // using opacity and z-index rather than display: none !important;
  opacity: 0 !important;
  z-index: -1 !important;
}

@media (min-width: 837px) {
  .flex-md-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline {
    display: inline-block !important;
  }
}

@media (min-width: 1140px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }
}

.w-auto {
  width: auto;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

@media (min-width: 768px) {
  .w-md-auto {
    width: auto;
  }

  .w-md-100 {
    width: 100%;
  }
}

@media (min-width: 1140px) {
  .w-lg-auto {
    width: auto;
  }

  .w-lg-100 {
    width: 100%;
  }
}

// flex system

.flex-block-20,
.flex-block-30,
.flex-block-40,
.flex-block-50,
.flex-block-60,
.flex-block-70,
.flex-block-80 {
  width: 100%;
  max-width: 100%;

  &:nth-child(n + 2) {
    margin-top: 24px;

    &.order-1 {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  @media (min-width: 768px) {
    &:nth-child(n + 2) {
      margin-top: 0;
    }
  }
}

@for $order from 1 through 4 {
  .order-#{$order} {
    order: $order;
  }
}

// 2) Breakpoint-specific order classes
@each $bp, $val in $breakpoints {
  @include respond($bp) {
    @for $order from 1 through 4 {
      .order-#{$bp}-#{$order} {
        order: $order;
      }
    }
  }
}

@media (min-width: 768px) {
  .flex-block-20 {
    max-width: 20%;
    flex-grow: 0.2;
  }

  .flex-block-30 {
    max-width: 30%;
    flex-grow: 0.3;
  }

  .flex-block-40 {
    max-width: 40%;
    flex-grow: 0.4;
  }

  .flex-block-50 {
    max-width: 50%;
    flex-grow: 0.5;
  }

  .flex-block-60 {
    max-width: 60%;
    flex-grow: 0.6;
  }

  .flex-block-70 {
    max-width: 70%;
    flex-grow: 0.7;
  }

  .flex-block-80 {
    max-width: 80%;
    flex-grow: 0.8;
  }
}
.pt-xs {
  padding-top: 8px;
}

.pb-xs {
  padding-bottom: 8px;
}
.pt-m {
  padding-top: 24px;
}

.pb-m {
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .flex-block-pad-5 {
    padding-left: 5%;
  }

  .flex-block-pad-right-5 {
    padding-right: 5%;
  }

  .flex-block-pad-10 {
    padding-left: 10%;
  }
}

@media (min-width: 1140px) {
  .flex-block-pad-lg-5 {
    padding-left: 5%;
  }

  .flex-block-pad-right-lg-5 {
    padding-right: 5%;
  }

  .flex-block-pad-lg-10 {
    padding-left: 10%;
  }
}

.position-relative {
  position: relative;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.img-fullheight {
  height: 100% !important;
  object-fit: cover;
}



// ─────────────────────────────────────────────
// GRID COLUMNS SYSTEM
// ─────────────────────────────────────────────
.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  margin: 0 auto;
  * {
    overflow-wrap: break-word;
  }
}

@for $cols from 1 through 12 {
  .grid-#{$cols} {
    grid-column: span #{$cols};
  }
}

@for $i from 1 through 12 {
  .grid-col-start-#{$i} {
    grid-column-start: #{$i};
  }
}

@each $bp, $val in $breakpoints {
  @include respond($bp) {
    @for $cols from 1 through 12 {
      .grid-#{$bp}-#{$cols} {
        grid-column: span #{$cols};
      }
    }
  }
}

// ─────────────────────────────────────────────
// GRID-GAP SYSTEM
// ─────────────────────────────────────────────

// 1) Define gap sizes in a map
$grid-gap-values: (
        0:   0px,
        8:   8px,
        12:  12px,
        16:  16px,
        24:  24px,
        32:  32px,
        40:  40px,
        48:  48px,
        56:  56px,
        64:  64px,
        72:  72px,
        80:  80px,
        88:  88px,
        96:  96px,
        104: 104px,
        120: 120px
);

@mixin generate-gap-classes($bp: null) {
  @each $gap-size, $gap-value in $grid-gap-values {
    // Build the optional breakpoint prefix (md-, lg-, etc.)
    $bp-prefix: if($bp, "#{$bp}-", "");

    // Horizontal gap
    .grid-gap-#{$bp-prefix}h-#{$gap-size} {
      column-gap: $gap-value; // or grid-column-gap
    }

    // Vertical gap
    .grid-gap-#{$bp-prefix}v-#{$gap-size} {
      row-gap: $gap-value; // or grid-row-gap
    }
  }
}

@include generate-gap-classes();

@each $bp, $val in $breakpoints {
  @include respond($bp) {
    @include generate-gap-classes($bp);
  }
}





// ─────────────────────────────────────────────
// BORDER RADIUS SYSTEM
// ─────────────────────────────────────────────

// 1) Define gap sizes in a map
$border-radius-values: (
  0: 0px,
  4: 4px,
  6: 6px,
  8: 8px
);

// 2) Generate base gap classes
@each $border-radius, $radius-value in $border-radius-values {
  .border-radius-#{$border-radius} {
    border-radius: $radius-value;
  }
}

// 3) Generate breakpoint-specific gap classes
@each $bp, $val in $breakpoints {
  @include respond($bp) {
    @each $border-radius, $radius-value in $border-radius-values {
      .border-radius-#{$bp}-#{$border-radius} {
        border-radius: $radius-value;
      }
    }
  }
}


// ─────────────────────────────────────────────
// COMBINED OFFSET & SPAN CLASSES
// ─────────────────────────────────────────────

// Mixin to generate combined offset/span classes for a 12-column grid.
// The naming convention is:
//    .grid-{breakpoint-?}offset-{offset}-{span}
// For example: .grid-md-offset-2-4 means "at the md breakpoint, start at column (2+1)=3 and span 4 columns".
//
// We loop through possible offset values from 1 to 11 (offset 0 is redundant since grid-X already covers that).
// For each offset, we allow spans such that offset + span <= 12.
@mixin generate-combined-offset-span-classes($bp: null) {
  // Create a breakpoint prefix if provided (e.g., "md-")
  $bp-prefix: if($bp, "#{$bp}-", "");

  // Loop through offsets from 1 through 11.
  @for $offset from 1 through 11 {
    // The maximum allowed span is 12 minus the offset value.
    $max-span: 12 - $offset;
    @for $span from 1 through $max-span {
      .grid-#{$bp-prefix}offset-#{$offset}-#{$span} {
        // Calculate starting column as offset + 1.
        // The shorthand "grid-column" sets both the start line and the span.
        grid-column: #{ $offset + 1 } / span #{$span};
      }
    }
  }
}

// Generate base (non-breakpoint) combined classes.
@include generate-combined-offset-span-classes();

// Generate breakpoint-specific combined classes.
@each $bp, $val in $breakpoints {
  @include respond($bp) {
    @include generate-combined-offset-span-classes($bp);
  }
}

