@import 'base';
@import 'blocks/contact-modal';
@import 'helpers/splide';

// hero
html[data-theme='light'] {
  .home-hero-section {
    .section-bg {
      .bg-video-outer {
        .bg-video {
          object-fit: cover;
          filter: brightness(1);
        }
      }
    }
  }
}

.home-hero-section {
  padding-top: calc(var(--header-height) + var(--mobile-banner-height) + 16px) !important;
  background: transparent;
  .section-bg {
    .bg-video-outer {
      position: absolute;
      top: calc(var(--header-height) + var(--mobile-banner-height) + 220px) !important;
      @media (min-width: 837px) {
        top: 144px !important;
        width: 100%;
      }
      @media (min-width: 1440px) {
        top: 0 !important;
        height: -webkit-fill-available;
      }
      .bg-video {
        width: 100%;
        height: 100%;
        filter: brightness(1.7);
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        z-index: 2;
        background: linear-gradient(0deg, var(--bg-color) 15%, rgba(255, 255, 255, 0) 100%);
        @media (min-width: 837px) {
          background: linear-gradient(0deg, var(--bg-color) 40%, rgba(255, 255, 255, 0) 100%);
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 50%;
        z-index: 2;
        background: linear-gradient(180deg, var(--bg-color) 15%, rgba(255, 255, 255, 0) 100%);
        @media (min-width: 837px) {
          background: linear-gradient(180deg, var(--bg-color) 40%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }
  }
  .page-heading {
    font-size: 56px;
    line-height: 56px;
    margin-bottom: 160px;
    @media (min-width: 837px) {
      font-size: 56px;
      line-height: 56px;
      margin-bottom: 48px;
    }
    span {
      &:first-child {
        max-width: 340px;
        @media (min-width: 837px) {
          max-width: 100%;
        }
      }
      &:last-child {
        font-size: 40px;
        line-height: 40px;
        max-width: 340px;
        @media (min-width: 837px) {
          font-size: 56px;
          line-height: 56px;
          max-width: 100%;
        }
      }
    }
  }
  .page-text {
    margin-bottom: 70px;
    font-family: "neue-haas-grotesk-display";
    max-width: 340px;
    @media (min-width: 837px) {
      max-width: 100%;
    }
  }
  .text-hero-section,
  .amd-hero-section,
  .split-hero-section {
    @media (min-width: 1140px) {
      min-height: 750px;
    }
  }
  .text-hero-section {
    border-radius: 4px;
    border: 1px solid rgba(56, 58, 74, 0.8);
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    @media (min-width: 837px) {
      padding: 32px 30px;
      gap: 0;
    }
    p {
      max-width: 200px;
    }
  }
  .amd-hero-section {
    background: url('/static/img/homepage/amd-background.png');
    background-size: cover;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    min-height: 500px;
    border-radius: 4px;
    @media (min-width: 837px) {
      padding: 32px 30px;
    }
    &__title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: var(--spacing-xxxs);
    }
  }
  .split-hero-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media (min-width: 837px) {
      height: 750px;
    }
  }
  .katerina-hero-section {
    background: url('/static/img/homepage/katerina-business-development.png');
    background-size: cover;
    background-position: center;
    border-radius: 4px;
    display: flex;
    align-items: flex-end;
    padding: var(--spacing-sm) var(--spacing-xs);
    color: #fff;
    flex: 1 1 50%;
    min-height: 370px;
    @media (min-width: 1140px) {
      height: 50%;
    }
    a {
      color: #fff;
    }
  }
  .blue-section {
    border-radius: 4px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    flex: 1 1 50%;
    @media (min-width: 837px) {
      padding: var(--spacing-sm) var(--spacing-xs);
    }
    @media (min-width: 1140px) {
      height: 50%;
    }
    &__title {
      font-size: 38px;
      font-style: normal;
      font-weight: 500;
      line-height: 41px;
      margin-bottom: 16px;
    }
    .btn {
      margin-top: 16px;
    }
  }
}

.continuous-carousel {
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 837px) {
    flex-direction: row;
    align-items: center;
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 179px;
      width: 40px;
      height: 50px;
      background: linear-gradient(90deg, var(--bg-color) 0%, rgba(0,0,0,0) 100%);
      z-index: 2;
    }
    &::after {
      content: '';
      position: absolute;
      top: -10px;
      right: 0;
      width: 40px;
      height: 50px;
      background: linear-gradient(270deg, var(--bg-color) 0%, rgba(0,0,0,0) 100%);
      z-index: 2;
    }
  }
  &__text {
    white-space: nowrap;
    padding-right: 8px;
    margin-bottom: 16px;
    font-size: 16px;
  }
  @include logo-slider('--tech-track-width', 'techs', 50px);
  .logo-slider {
    &__slide {
      transform: scale(1);
      margin: 0 20px;
    }
  }
}

.secure-growth-section {
  .left-cards-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .left-card:nth-of-type(3) {
      .left-card__line.vertical {
        height: 48px;
        bottom: -48px;
      }
    }
    @media (min-width: 837px) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 32px 16px;
      .left-card:nth-of-type(1) {
        flex: 0 0 50%;
        margin: 0 auto;
        .left-card__line.vertical {
          @media (min-width: 837px) {
            height: 204px;
            bottom: -204px;
          }
        }
      }
      .left-card:nth-of-type(2),
      .left-card:nth-of-type(3) {
        flex: 0 0 calc(50% - 8px);
      }
      .left-card:nth-of-type(2) {
        .left-card__line.vertical {
          @media (min-width: 837px) {
            display: none;
          }
        }
        .left-card__line.horizontal {
          @media (min-width: 837px) {
            display: block;
            width: 16px;
            right: -16px;
          }
        }
      }
      .left-card:nth-of-type(3) {
        .left-card__line.vertical {
          display: none;
        }
      }
    }
    @media (min-width: 1140px) {
      .left-card:nth-of-type(1),
      .left-card:nth-of-type(3) {
        .left-card__line.horizontal {
          display: none;
        }
      }
    }
    @media (min-width: 1440px) {
      justify-content: space-between;
      .left-card:nth-of-type(1),
      .left-card:nth-of-type(2),
      .left-card:nth-of-type(3) {
        flex: 0 0 100%;
        .left-card__line.horizontal {
          width: 48px;
          right: -48px;
          display: block;
        }
        .left-card__line.vertical {
          height: 33px;
          bottom: -33px;
          display: block;
        }
      }
      .left-card:nth-of-type(3) {
        .left-card__line.vertical {
          display: none;
        }
      }

    }
  }
  .left-card {
    border-radius: 6px;
    background: var(--bg-color-foreground);
    padding: var(--spacing-xs);
    display: flex;
    align-items: center;
    gap: 24px;
    position: relative;

    &__icon {
      padding: var(--spacing-xxs);
      border-radius: 4px;
      background: linear-gradient(71deg, #A12BF0 11.03%, #3241E7 64.4%, #2E3CD3 98.16%);
      width: 75px;
      height: 75px;
      color: #fff;
    }
    &__subtitle {
      color: #878787;
      font-size: 14px;
      font-weight: 400;
    }
    &__title {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
    }

    &__line {
      display: flex;
      align-items: center;
      position: absolute;
      background: var(--line-shadow);
      filter: drop-shadow(0px 0px 10px var(--line-shadow-bg));
      z-index: 2;
      &.vertical {
        left: calc(50% - 1px);
        flex-direction: column;
        width: 2px;
        height: 16px;
        bottom: -16px;
        @media (min-width: 1140px) {
          height: 42px;
          bottom: -42px;
        }

        .left-card__line__dot {
          position: absolute;
          background: #A12BF0;
          border-radius: 50%;
          width: 6px;
          height: 6px;
          left: calc(50% - 3px);
          z-index: 2;
          filter: drop-shadow(1px 1px 15px #D4B1FF);
          &:first-child {
            top: -3px;
          }
          &:last-child {
            bottom: -3px;
          }
        }
      }
      &.horizontal {
        display: none;
        @media (min-width: 1140px) {
          display: block;
        }
        flex-direction: row;
        height: 2px;
        width: 48px;
        right: -48px;
        top: calc(50% - 1px);
        .left-card__line__dot {
          position: absolute;
          background: #A12BF0;
          border-radius: 50%;
          width: 6px;
          height: 6px;
          top: calc(50% - 3px);
          z-index: 2;
          filter: drop-shadow(1px 1px 15px #D4B1FF);
          &:first-child {
            left: -3px;
          }
          &:last-child {
            right: -3px;
          }
        }
      }
    }
  }
  .secure-growth-content {
    border-radius: 4px;
    border: 1px solid #A12BF0;
    padding: 24px;
    font-size: 16px;
    @media (min-width: 837px) {
      padding: 32px;
    }
    .btn {
      position: relative;
      z-index: 2;
      margin-top: 64px;
      @media (min-width: 837px) {
        margin-top: 0;
      }
      //&::after {
      //  content: '';
      //  position: absolute;
      //  left: 50%;
      //  transform: translateX(-50%);
      //  bottom: -6px;
      //  border-radius: 50%;
      //  background: var(--line-shadow-bg);
      //  filter: blur(74px);
      //  width: 250px;
      //  z-index: 0;
      //  height: 80px;
      //}
    }
  }
}

.build-deploy-section {
  padding-bottom: 48px;
  .section-heading {
    max-width: 440px;
  }
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 60%;
    height: 80%;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.00);
    opacity: 0.3;
    background: #25007C;
    filter: blur(74px);
    z-index: 0;
  }
  .container {
    z-index: 1;
    position: relative;
  }
  .section-text {
    max-width: 410px;
  }
  .grid-12:last-child {
    @media (min-width: 1140px) {
      justify-self: flex-end;
    }
  }
  .basic-list {
    margin-top: 20px;
  }
  .nvidia-video-outer {
    position: relative;
    top: -1%;
    width: 100%;
    margin: 88px auto 0;
    padding: 30px 0;
    max-width: 250px;
    @media (min-width: 837px) {
      margin: 0;
    }
    // Vertical lines
    &::before {
      content: '';
      position: absolute;
      opacity: 0.6;
      background: linear-gradient(180deg, #010314 0%, #BC85FF 13.82%, #010314 100%);
      width: 1px;
      height: 145%;
      left: -1px;
      top: -5%;
    }
    &::after {
      content: '';
      position: absolute;
      opacity: 0.6;
      background: linear-gradient(180deg, #010314 0%, #BC85FF 13.82%, #010314 100%);
      width: 1px;
      height: 145%;
      right: -1px;
      top: -3%;
    }
    .nvidia-video-inner {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      // Horizontal lines
      &::before {
        content: '';
        position: absolute;
        opacity: 0.6;
        background: linear-gradient(90deg, #010314 0%, #BC85FF 13.82%, #EAEAEA 56.45%, #010314 100%);
        width: 130%;
        height: 1px;
        top: -1px;
        left: -15%;
      }
      &::after {
        content: '';
        position: absolute;
        opacity: 0.6;
        background: linear-gradient(90deg, #010314 0%, #BC85FF 13.82%, #EAEAEA 56.45%, #010314 100%);
        width: 130%;
        height: 1px;
        bottom: 88px;
        left: -15%;
      }
      .nvidia-video {
        position: relative;
        width: 100%;
        height: auto;
        padding-bottom: 20px;
        margin-bottom: 40px;
      }
      .logo-glow {
        position: absolute;
        bottom: -6px;
        border-radius: 50%;
        background: var(--primary-blue);
        filter: blur(74px);
        width: 100%;
        height: 30px;
      }
      svg {
        width: 130px;
        margin: 0 auto;
      }
    }
  }
}


.low-latency-section {
  padding-bottom: 48px;
  @media (min-width: 1140px) {
    padding-bottom: var(--basic-section-gap);
  }
  .low-latency-grid {
    @media (min-width: 1140px) {
      height: 750px;
    }
  }
  .low-latency-panel {
    background: url('/static/img/homepage/low-latency-bg.png');
    background-size: cover;
    padding: 32px 30px;
    border-radius: 4px;
    .section-heading {
      margin-bottom: 40px;
      @media (min-width: 1140px) {
        margin-bottom: 32px;
      }
    }
  }
  .split-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 16px;
    @media (min-width: 1140px) {
      gap: 16px;
    }
  }
  .grey-section {
    background: #111221;
    border-radius: 4px;
    padding: var(--spacing-sm);
    @media (min-width: 1140px) {
      height: 50%;
    }
    &__icon {
      width: 72px;
      height: 72px;
    }
    &__title {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin-top: 24px;
      margin-bottom: 16px;
      max-width: 355px;
    }
    p {
      max-width: 355px;
    }
  }
  .blue-section {
    border-radius: 4px;
    padding: var(--spacing-sm);
    @media (min-width: 1140px) {
      height: 50%;
    }
    &__icon {
      width: 65px;
      height: 75px;
      color: #fff;
    }
    &__title {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin-top: 24px;
      margin-bottom: 16px;
    }
    .basic-list {
      max-width: 355px;
    }
  }

}

.core-values-section {
  padding-top: 48px;
  @media (min-width: 1140px) {
    padding-top: var(--basic-section-gap);
  }
  .section-subtitle {
    font-size: 56px;
    line-height: 52px;
    max-width: 400px;
    margin-bottom: 16px;
    @media (min-width: 837px) {
      max-width: 290px;
      font-size: 38px;
      line-height: 41px;
    }
  }
  .grid-lg-3 {
    font-size: 16px;
    @media (min-width: 1140px) {
      &:nth-child(1) {
        grid-column: 1 / span 3;
      }
      &:nth-child(2) {
        grid-column: 5 / span 3;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: -10%;
          left: -60px;
          width: 1px;
          height: 120%;
          opacity: 0.6;
          background: linear-gradient(180deg, #F2F2F2 0%, #141315 46.5%, #020103 63%, #F2F2F2 100%);
        }
        &::before {
          content: '';
          position: absolute;
          top: -10%;
          right: -40px;
          width: 1px;
          height: 120%;
          opacity: 0.6;
          background: linear-gradient(190deg, #F2F2F2 0%, #141315 46.5%, #020103 63%, #F2F2F2 100%);
        }
      }
      &:nth-child(3) {
        grid-column: 9 / span 3;
      }
    }
  }
  .core-value {
    display: flex;
    gap: 8px;
    @media (min-width: 837px) {
      flex-direction: column;
    }
    &:first-child {
      margin-bottom: var(--spacing-xxs);
    }
    svg {
      flex: 0 0 24px;
    }
  }
}

.support-section {
  .section-text {
    max-width: 735px;
  }
  .support-bullet {
    font-size: 16px;
    display: flex;
    gap: 8px;
    svg {
      position: relative;
      top: 0px;
    }
  }
  .support-message {
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    padding: 8px 12px;
    display: flex;
    gap: 12px;
    align-items: center;
    position: relative;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    &::after {
      width: 1px;
      height: 16px;
      background: #000;
    }
    &__avatar {
      width: 40px;
      height: 40px;
    }
    &__name {
      font-weight: 700;
      &__time {
        font-weight: 500;
        color: #878787;
        font-size: 12px;
      }
    }
    &__content {
      font-size: 12px;
      font-weight: 400;
    }

    &__line {
      display: flex;
      align-items: center;
      position: absolute;
      background: #383A4A;

      &.vertical {
        left: calc(50% - 1px);
        flex-direction: column;
        width: 2px;
        height: 24px;
        bottom: -24px;

        .support-message__line__dot {
          position: absolute;
          background: #383A4A;
          border-radius: 50%;
          width: 6px;
          height: 6px;
          left: calc(50% - 3px);
          z-index: 2;

          &:first-child {
            top: -2px;
          }

          &:last-child {
            bottom: -4px;
          }
        }
      }
    }
  }
  .support-slack-icon {
    position: absolute;
    bottom: -28px;
    right: -8px;
  }
}


.faq-section {
  .accordion-item {
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    height: auto;
    border-top: 1px solid transparent;
    border-image: linear-gradient(90deg, #D9D9D9 0%, #141315 6.5%, #020103 47%, #D9D9D9 95.5%);
    border-image-slice: 1;
    border-image-width: 1;
    border-image-outset: 0;
    border-image-repeat: stretch;
    &:last-child {
      border-bottom: 1px solid transparent;
    }
    &.hidden {
      display: none;
    }

    &__header {
      padding: 12px 8px;
      text-align: left;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      color: #383A4A;
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
      transition: all 200ms ease-in-out;
      width: 100%;
      gap: 12px;

      .accordion-active-icon {
        stroke: #383A4A;
        flex: 0 0 16px;
        width: 8px;
        height: 8px;
        transform: rotate(-90deg);
        transition: 0.3s ease-in-out;

        @media (min-width: 837px) {
          width: 16px;
          height: 16px;
        }
      }

      @media (min-width: 837px) {
        font-size: 1rem;
      }

      &.active {
        .accordion-active-icon {
          transform: rotate(0);
        }
      }
    }

    &__body {
      color: #383A4A;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      &-content {
        padding: 8px 16px 8px 38px;
        text-align: left;
        ul,
        ol {
          list-style-type: square;
          margin-left: 20px;
        }
      }
    }
  }

  .section-text {
    max-width: 285px;
    @media (min-width: 393px) {
      max-width: 100%;
    }
  }


}

.tech-team-section {
  .page-heading-small {
    @media (min-width: 837px) {
      padding-right: 8px;
    }
  }
  .tech-team-left {
    background: #fff;
    border-radius: 4px;
    padding: 24px;
    @media (min-width: 837px) {
      padding: 6px;
    }
  }
  .tech-team-right {
    background: #fff;
    padding: 24px 16px;
    border-radius: 4px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    @media (min-width: 1140px) {
      padding: 20px 32px 28px;
    }
  }
  .section-heading {
    margin: 16px auto 24px;
    text-align: center;
    @media (min-width: 837px) {
      margin: 0;
      text-align: left;
      padding-left: 24px;
      padding-top: 24px;
    }
  }
  .team-profile {
    margin: 16px auto 16px;
    color: #383A4A;
    max-width: 400px;
    width: 100%;
    line-height: 18px;
    @media (min-width: 837px) {
      margin: 16px 0 0;
      padding-left: 8px;
      padding-bottom: 8px;
    }
  }
  .img-fullheight {
    max-width: 400px;
    margin: 0 auto;
    padding-top: 40px;
    @media (min-width: 837px) {
      padding-top: 0;
    }
    @media (min-width: 1140px) {
      max-width: 100%;
      margin: 0;
    }
  }
}

.vps-locations-section {
  .vps-locations-bg {
    position: absolute;
    right: 0;
    top: -8vh;
    width: 144vw;
    @media(min-width: 537px){
      width:100vw;
    }

    @media(min-width: 837px){
      width:58vw;
      top:-4vh
    }
    @media(min-width: 1140px){
      width:55vw;
      top:0;
    }
  }
  overflow: hidden;
  position: relative;
  
  @media (min-width: 1140px) {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  
  .vps-locations {
    position: relative;
    display: flex;
    flex-direction: column;
    
    @media (min-width: 837px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  
  .vps-locations-content {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    @media (min-width: 837px) {
      max-width: 986px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  
  .section-heading {
    padding: 0 16px;
    position: relative;
    z-index: 1;
    margin-bottom: 32px;
    
    @media (min-width: 837px) {
      padding: 0;
      margin-bottom: 0;
      //width: 45%;
    }
  }
  
  .locations-accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
    
    @media (min-width: 837px) {
      //width: 55%;
      margin-top: 0;
    }
    
    .accordion-item {
      width: 100%;
      overflow: hidden;
      border-top: 1px solid transparent;
      border-image: linear-gradient(90deg, var(--accordion-border-first) 0%, var(--accordion-border-middle-1) 6.5%, var(--accordion-border-middle-2) 47%, var(--accordion-border-last) 95.5%);
      border-image-slice: 1;
      border-image-width: 1;
      border-image-outset: 0;
      border-image-repeat: stretch;
      &:last-child {
        border-bottom: 1px solid transparent;
      }
      &__header {
        padding: 12px 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
        position: relative;
        
        .accordion-active-icon {
          margin-right: 8px;
          transition: transform 0.3s ease;
          transform: rotate(-90deg);
        }
        
        .region-count {
          padding: 2px 8px;
          color:#878787
        }
        
        &.active {
          .accordion-active-icon {
            transform: rotate(0);
          }
        }
      }
      
      &__body {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        
        &-content {
          padding: 0 16px 16px;
        }
      }
    }
    
    .locations-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 12px;
      
      .location-item {
        //padding: 8px 0;
        
        .city {
          font-weight: 500;
        }
        
        .country {
          opacity: 0.7;
        }
      }
    }
  }
}
