@import '~nice-select2/dist/css/nice-select2.css';

.mfp-hide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 10;

  .background-blur {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1000;
    background: var(--modal-bg);
    backdrop-filter: blur(5px);
  }

  &.on {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 9999;

    .background-blur {
      z-index: 1;
    }
  }
}



.modal-open {
  overflow: hidden;
  width: 100%;
}

.modal-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  color: var(--text-color);
  position: relative;
  z-index: 2;
  @media (min-width: 837px) {
    padding: 16px 24px;
  }
  .th-logo {
    position: absolute;
    top: 25px;
    left: 220px;
  }
  .container {
    overflow-y: auto;
    padding-top: 40px;
  }
  &-close {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1046;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: none;
    outline: 0;
    padding: 0;
    @media (min-width: 837px) {
      right: -22px;
    }
    @media (min-width: 1140px) {
      right: 146px;
    }
    @media (min-width: 1440px) {
      right: 237px;
    }

    svg {
      width: 30px;
      height: 30px;
      color: var(--text-color);
    }
  }

  .modal-header {
    gap: var(--spacing-lg);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: 16px;
    @media (min-width: 837px) {
      flex-direction: row;
      margin-bottom: 16px;
      justify-content: center;
    }
  }
  .section-heading {
    margin-bottom: 0;
    text-align: center;
    @media (min-width: 837px) {
      font-size: 66px;
      line-height: 70px;
    }
    @media (min-width: 1140px) {
      font-size: 72px;
      line-height: 74px;
    }
  }

  .section-subtitle {
    margin: 0 auto 32px;
    @media (min-width: 393px) {
      width: 100%;
    }
    @media (min-width: 837px) {
      width: 650px;
    }
  }

  .modal-contact-form {
    margin: 0 auto;
    @media (min-width: 393px) {
      width: 100%;
    }
    @media (min-width: 837px) {
      width: 809px;
    }
  }

  .team-outer {
    max-width: 200px;
    flex: 0 0 200px;


    margin-bottom: 24px;
    position: relative;
    @media (min-width: 837px) {
      margin-bottom: 0;
    }
    // Vertical lines
    &::before {
      content: '';
      position: absolute;
      opacity: 0.6;
      background: linear-gradient(180deg, rgba(20, 19, 21, 0) 0%, var(--text-color) 14%, var(--text-color) 80%, rgba(20, 19, 21, 0) 100%);
      width: 1px;
      height: 130%;
      left: 0;
      top: -12%;
    }
    &::after {
      content: '';
      position: absolute;
      opacity: 0.6;
      background: linear-gradient(180deg, rgba(20, 19, 21, 0) 0%, var(--text-color) 14%, var(--text-color) 80%, rgba(20, 19, 21, 0) 100%);
      width: 1px;
      height: 130%;
      right: 0;
      top: -12%;
    }
    .team-inner {
      position: relative;
      // Horizontal lines
      &::before {
        content: '';
        position: absolute;
        opacity: 0.6;
        background: linear-gradient(90deg, rgba(20, 19, 21, 0) 0%, var(--text-color) 5%, var(--text-color) 45%, rgba(20, 19, 21, 0) 100%);
        height: 1px;
        top: 0;
        left: -15%;
        width: 360px;
        @media (min-width: 393px) {
          width: 130%;
          background: linear-gradient(90deg, rgba(20, 19, 21, 0) 0%, var(--text-color) 15%, var(--text-color) 85%, rgba(20, 19, 21, 0) 100%);
        }
        @media (min-width: 837px) {
          width: 809px;
          background: linear-gradient(90deg, rgba(20, 19, 21, 0) 0%, var(--text-color) 5%, var(--text-color) 45%, rgba(20, 19, 21, 0) 100%);
        }
      }
      &::after {
        content: '';
        position: absolute;
        opacity: 0.6;
        background: linear-gradient(90deg, rgba(20, 19, 21, 0) 0%, var(--text-color) 5%, var(--text-color) 45%, rgba(20, 19, 21, 0) 100%);
        height: 1px;
        bottom: 0;
        left: -15%;
        width: 360px;
        @media (min-width: 393px) {
          width: 130%;
          background: linear-gradient(90deg, rgba(20, 19, 21, 0) 0%, var(--text-color) 15%, var(--text-color) 85%, rgba(20, 19, 21, 0) 100%);
        }
        @media (min-width: 837px) {
          width: 809px;
          background: linear-gradient(90deg, rgba(20, 19, 21, 0) 0%, var(--text-color) 5%, var(--text-color) 45%, rgba(20, 19, 21, 0) 100%);
        }
      }
    }
  }
}
