// root styles
$breakpoints: (
  sm: 393px,
  md: 837px,
  lg: 1140px,
  xl: 1440px,
);

@mixin respond($bp) {
  $value: map-get($breakpoints, $bp);
  @if $value {
    @media (min-width: #{$value}) {
      @content;
    }
  } @else {
    // If no valid breakpoint is found, output as-is (for base rules)
    @content;
  }
}

@import url("../../public_html/static/fonts/inter.css");

:root {
  --header-height: 80px;
  --page-max-width: 100%;
  --page-gutter: var(--spacing-xxs);
  --basic-section-gap: 56px;
  --small-section-gap: 48px;

  --mobile-banner-height: 188px;

  @media (min-width: 393px) {
    --page-max-width: 100%;
    --page-gutter: 0;
  }
  @media (min-width: 837px) {
    --mobile-banner-height: 0px !important;
  }
  @media (min-width: 837px) {
    --page-max-width: 809px;
    --basic-section-gap: 96px;
  }
  @media (min-width: 1140px) {
    --page-max-width: 1120px;
  }
  @media (min-width: 1440px) {
    --page-max-width: 1297px;
  }

  // Fonts
  --default-font: 'Inter', sans-serif;

  // Spacing
  --spacing-xxxs: 8px;
  --spacing-xxs: 16px;
  --spacing-xs: 24px;
  --spacing-sm: 32px;
  --spacing-md: 40px;
  --spacing-lg: 48px;
  --spacing-xl: 56px;
  --spacing-xxl: 64px;
  --spacing-xxxl: 72px;


  // Colors
  --primary-blue: #052ED2;

  // theme light
  --bg-color: #F2F2F2;
  --bg-color-foreground: #fff;
  --text-color: #383A4A;
  --text-color-transparent: rgba(56, 58, 74, 0);
  --text-color-dull: #878787;
  --gradient-grey-first: #383A4A;
  --gradient-grey-last: #383A4A;
  --gradient-grey-position: 0;
  --gradient-grey-angle: 87deg;
  --accordion-border-first: #D9D9D9;
  --accordion-border-middle-1: #141315;
  --accordion-border-middle-2: #020103;
  --accordion-border-last: #D9D9D9;
  --menu-bg: rgba(195, 195, 195, 0.30);
  --menu-text-color-dull: #878787;
  --menu-mobile-bg: #F2F2F2;
  --gradient-colored-bg: #d7d7d7;

  --nav-color: #383A4A;
  --nav-color-active: #000;

  --modal-bg: #fff;

  --btn-glow: transparent;
  --line-shadow: #A12BF0;
  --line-shadow-bg: transparent;

  --ai-agents-btn-color: #6639eb;
  --ai-agents-btn-icon: #6639eb;


  // vps map colors
  --vps-locations-overlay: rgba(255, 255, 255, 0.5);
  --vps-locations-tag-bg-color1: #A12BF0;
  --vps-locations-tag-bg-color2: #2E3CD3;
  --vps-locations-tag-shadow: #6200b2;


  &[data-theme="dark"] {
    --bg-color: #010314;
    --bg-color-foreground: #232436;
    --text-color: #fff;
    --text-color-transparent: rgba(255, 255, 255, 0);
    --text-color-dull: #fff;
    --gradient-grey-first: #fff;
    --gradient-grey-last: #737373;
    --gradient-grey-position: 50%;
    --gradient-grey-angle: 190deg;
    --accordion-border-first: #858585;
    --accordion-border-middle-1: #FFFFFF;
    --accordion-border-middle-2: #FFFFFF;
    --accordion-border-last: #2B2B2B;
    --menu-bg: rgba(234, 234, 234, 0.12);
    --menu-text-color-dull: #818BD7;
    --menu-mobile-bg: linear-gradient(359deg, #8008FF -52.09%, #25199B -17.02%, #171515 37.76%, #050219 73.94%);
    --gradient-colored-bg: #06127A;

    --nav-color: #d9d9d9;
    --nav-color-active: #fff;

    --modal-bg: linear-gradient(359deg, #070F53 0.43%, #000 48.64%);

    --btn-glow: #052ED2;
    --line-shadow: #A12BF0;
    --line-shadow-bg: #052ED2;

    --ai-agents-btn-color: #fff;
    --ai-agents-btn-icon: #fff;

    // vps map colors
    --vps-locations-overlay: rgba(14, 0, 124, 0.40);
    --vps-locations-tag-bg-color1: #010314;
    --vps-locations-tag-bg-color2: #252840;
    --vps-locations-tag-shadow: #C995FF;
  }
}


html.force-dark {
  --bg-color: #010314;
  --bg-color-foreground: #232436;
  --text-color: #fff;
  --gradient-grey-first: #fff;
  --gradient-grey-last: #181818;
  --gradient-grey-position: 50%;
  --gradient-grey-angle: 190deg;
  --menu-bg: rgba(234, 234, 234, 0.12);
  --gradient-colored-bg: #06127A;

  --nav-color: #d9d9d9;
  --nav-color-active: #fff;

  --modal-bg: linear-gradient(359deg, #070F53 0.43%, #000 48.64%);

  --btn-glow: #052ED2;
  --line-shadow: #A12BF0;
  --line-shadow-bg: #052ED2;

  // vps map colors
  --vps-locations-overlay: rgba(14, 0, 124, 0.40);
  --vps-locations-tag-bg-color1: #010314;
  --vps-locations-tag-bg-color2: #252840;
  --vps-locations-tag-shadow: #C995FF;
}
