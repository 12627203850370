/* stylelint-disable selector-max-type */
@import 'resets';
@import '../node_modules/svg-spritemap-webpack-plugin/sprites';
@import 'root';
@import 'fonts';
@import 'utils';
@import 'typography';
@import 'framework';
@import 'sections/header-navigation';
@import 'sections/footer';
@import 'forms';

html {
  font-size: 14px;
  box-sizing: border-box;
  @media (min-width: 1140px) {
    font-size: 16px;
  }
}

*, *::before, *::after {
  box-sizing: border-box;
}

//

body {
  @include default-font;

  background: var(--bg-color);
  color: var(--text-color);
  position: static !important; // fix calendly ios popup scroll to top
  overflow-x: hidden;

  /* stylelint-disable-next-line selector-no-qualifying-type */
  &.preload * {
    animation-duration: 0s !important;
    transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s,
      padding 0s, margin 0s !important;
  }
}

section {
  background: var(--bg-color);
  color: var(--text-color);
}

a {
  color: var(--text-color);
  transition: color 0.25s linear;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.dot-list {
  list-style: none;

  li {
    margin-bottom: 16px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '\2022';
      color: var(--primary-blue);
      font-weight: bold;
      display: inline-block;
      width: 1em;
      flex: 0 0 1em;
    }
  }
}

// page backbone
#scroll-wrap {
  overflow-x: hidden;
}

.section-bg {
  overflow: hidden;
  top: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  z-index: -1;
  background-size: cover;
  opacity: 1;
  transition: opacity 0.25s ease;
  overflow: hidden;
}

.screen-section,
.basic-section {
  position: relative;
}

.screen-section {
  height: calc(100vh - var(--sticky-height));
  min-height: 600px;
}

.basic-section {
  padding: var(--basic-section-gap) 0;

  &.next-section-bg-same {
    padding-bottom: var(--small-section-gap);
  }

  &.prev-section-bg-same {
    padding-top: var(--small-section-gap);
  }
}





// make offset for sticky header when header exists //.enable-sticky
.htt-header + #scroll-wrap {
  // padding-top: var(--header-height);
  .basic-section,
  .extended-section,
  .screen-section {
    &:first-child {
      padding-top: var(--header-height);
    }
  }

  .basic-section,
  .extended-section {
    &:first-child {
      .hero-content {
        @media (min-width: 1140px) {
          margin-top: 0;
        }
      }
    }
  }

  & > .screen-section:first-child {
    // margin-top: calc(-1 * var(--sticky-height));
    height: 100vh;
    // padding-top: var(--header-height);

    @media (min-width: 1140px) {
      // padding-top: 0;
      min-height: 768px;
      max-height: 130vw;
    }
  }
}




.dark-section {
  background: #010314;
  color: #fff;
}
.light-section {
  background: #F2F2F2;
  color: #232436;
}
.theme-section {
  background: var(--bg-color);
  color: var(--text-color);
}
.blue-section {
  background: var(--primary-blue);
}
