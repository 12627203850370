
@mixin inset($top: 0, $right: 0, $bottom: 0, $left: 0) {
  inset: $top $right $bottom $left;

  @supports not (inset: 0) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }
}

.container {
  width: 100%;
  padding: 0 16px;
  margin-right: auto;
  margin-left: auto;
  // max-width: calc($mw + $pad * 2);
  max-width: var(--page-max-width);

  &.full-mobile {
    padding: 0;

    @media (min-width: 1140px) {
      padding: 0 var(--page-gutter);
    }
  }
}

@mixin sprite-mask($name) {
  $sprite: map-get($sprites, $name);

  mask-image: url($sprite);
}

@mixin ultrawide-bg-fix($add-filter: true) {
  max-width: 1440px;
  left: 50%;
  transform: translateX(-50%);

  @if $add-filter {
    @media (min-width: 1441px) {
      @include bg-filter(
        linear-gradient(90deg, rgba(2, 6, 7, 0) 70%, var(--bg-color) 100%),
        'before'
      );
    }
  }
}

@mixin bg-filter($bg, $pseudo-element: 'after') {
  &::#{$pseudo-element} {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    background: $bg;
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 233, 147, 0.4);
  }

  100% {
    box-shadow: 0 0 0 8px rgba(0, 233, 147, 0);
  }
}

@mixin button-click {
  // transform: translateY(1px);

  &::after {
    box-shadow: 0 0 0 0 rgb(var(--text-color), 0.33);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
  }
}

.theme-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  .light {
    .theme-btn-icon-light {
      display: block;
    }
    .theme-btn-icon-dark {
      display: none;
    }
  }
  .dark {
    .theme-btn-icon-light {
      display: none;
    }
    .theme-btn-icon-dark {
      display: block;
    }
    &.theme-toggle {
      transform: rotate(360deg);
    }
  }
}

.theme-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 28px;
  height: 28px;
  background: transparent;
  border-radius: 50%;
  color: var(--text-color);
  position: absolute;
  transition: transform 500ms, left 500ms;
}

.btn {
  $this: &;

  position: relative;
  display: inline-block;
  padding: var(--spacing-xxs) var(--spacing-xs);

  background-image: linear-gradient(12deg, #8008FF 8.82%, #25199B 45.89%, #171515 136.98%, #050219 186.83%);
  color: #fff;
  border: none;
  border-radius: 25px;
  user-select: none;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: capitalize;
  line-height: 18px;
  white-space: nowrap;
  transition: 0.3s linear;
  cursor: pointer;
  z-index: 1;
  width: fit-content;

  &:hover {
    background: linear-gradient(27deg, #8008FF 50.16%, #25199B 104.16%, #171515 172.72%, #050219 220.39%);
    box-shadow: 0px 0px 12px 0px #8E6FED;
  }

  &:focus {
    box-shadow: 0 0 15px -2px #fff;
  }

  .btn-caption {
    flex-basis: 100%;
  }

  &.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &.btn-max-width {
    max-width: 100%;
    width: 100%;
  }
  &.ai-agents {
    display: flex;
    position: relative;
    height: 40px;
    border-radius: 40px;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    background: transparent;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: var(--spacing-xxs) var(--spacing-xxxs);
    margin: 0 0 24px;
    color: var(--ai-agents-btn-color);
    @media (min-width: 837px) {
      margin: 0 auto 48px;
    }
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 20px;
        border: 2px solid transparent;

      color: #050219;
      background: linear-gradient(71deg, #fff 0%, #A12BF0 11.03%, #3241E7 64.4%, #2E3CD3 98.16%) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
    .ai-agents__tag {
      background: linear-gradient(71deg, #A12BF0 11.03%, #3241E7 64.4%, #2E3CD3 98.16%) border-box;
      color: #fff;
      border-radius: 13px;
      padding: 4px 12px;
    }
    .ai-agents__icon {
      color: var(--ai-agents-btn-icon);
      margin-right: 8px;
    }
  }
}

.link-mask-icon,
.btn-mask-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    margin-top: -2px;
    mask-repeat: no-repeat;
    mask-position: center;
    flex-shrink: 0;
  }

  .btn-caption {
    flex-basis: initial;
  }
}

.link-mask-icon {
  color: var(--primary-blue);
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;

  &:not(:disabled):hover {
    color: var(--primary-blue);
    text-decoration: none;

    &::before {
      background-color: var(--primary-blue);
    }
  }

  &::before {
    width: 18px;
    height: 18px;
    background-color: var(--primary-blue);
  }

  .btn-caption {
    padding-left: 4px;
  }
}

.btn-mask-icon {
  padding-top: 14px;
  padding-bottom: 14px;

  &:not(:disabled):hover {
    &::before {
      background-color: var(--primary-blue);
    }
  }

  &::before {
    width: 24px;
    height: 24px;
    background-color: var(--text-color);
  }

  &.loading::before {
    margin-top: 0;
    margin-right: 2px;
    top: 0;
    width: 22px;
    height: 22px;
    mask-image: none;
    background: none;
  }

  .btn-caption {
    padding-left: 10px;
  }
}



@mixin aspect-ratio-wrap($ratio) {
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-top: $ratio;
  }

  &__inner {
    position: absolute;

    @include inset;
  }
}

// mask icons for buttons

$_mask-icons-list: play list ui-chat ui-join ui-download ui-external; // add more if needed

@each $letter in $_mask-icons-list {
  .mask-icon-#{$letter}::before {
    @include sprite-mask('#{$letter}');
  }
}

.mask-icon-ui-join::before {
  mask-size: 19px;
}

.mask-icon-ui-download::before {
  mask-size: 16px;
}

.mask-icon-ui-external::before {
  mask-size: 15px;
}







.mobile-menu-expander {
  margin: 0;
  padding: 12px 0;
  width: 100%;
  border: 0;
  background: none;
  color: var(--text-color);
  font-weight: 400;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  // &:hover {
  //   color: $btn-hover-bg;
  //   font-weight: 600;
  // }

  @media (min-width: 1140px) {
    display: none;
  }

  &.active {
    font-weight: 600;

    > svg {
      transform: rotate(-180deg);
    }

    // & + .mobile-menu-expandable {
    //   height: auto;
    // }
  }

  > svg {
    transition: transform 0.2s ease;
  }

  // & + .mobile-menu-expandable {
  //   height: 0;
  // }
}

.mobile-menu-expandable {
  overflow: hidden;
  transition: height 0.25s ease;
  height: 0;

  @media (min-width: 1140px) {
    height: auto !important;
  }
}



// cookie popup

.cookie-consent {
  visibility: hidden;
  opacity: 0;
  padding: 16px;
  position: fixed;
  left: 5px;
  bottom: 5px;
  right: 5px;
  z-index: 9999;
  background: var(--bg-color-foreground);
  color: var(--text-color);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.25s ease;
  }

  @media (min-width: 768px) {
    width: 425px;
    bottom: 15px;
    left: 18px;
    right: unset;
  }


  &__text {
    font-size: 14px;
    font-weight: 400;
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .cookie-consent-btn {
    padding: 12px;
    width: 40px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.link {
      border: 1px solid var(--text-color);
      border-radius: 18px;
      width: 36px;
      height: 36px;
      text-decoration: none;
    }
  }
}

.basic-list {
  $this: &;

  padding-left: 20px;
  list-style: disc;

  &__item {
    break-inside: avoid-column;
    display: flex;
    gap: 12px;
    font-weight: 400;
    svg {
      flex: 0 0 25px;
      position: relative;
      top: 2px;
    }

    &::marker {
      color: var(--primary-blue);
      font-size: 16px;
      line-height: 1;
    }

    &:not(:first-child) {
      margin-top: 8px;

      @media (min-width: 768px) {
        margin-top: 16px;
      }
    }
    &__heading {
      display: block;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &.lightning-bolt {
    padding-left: 0;

    #{$this}__item {
      padding-left: 32px;
      position: relative;
      list-style: none;
      margin-bottom: 28px;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        content: '';
        display: block;
        width: 25px;
        height: 25px;
        position: absolute;
        left: 0;
        top: 0;

        @include sprite('lightning-bolt', (color: #1F69FE));
      }
    }
    &.white {
      #{$this}__item {
        &::before {
          @include sprite('lightning-bolt', (color: #fff));
        }
      }
    }
  }

  &.multi-icon {
    padding-left: 0;

    #{$this}__item {
      position: relative;
      list-style: none;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    svg {
      width: 25px;
      height: 25px;
    }
  }
}

// ribbon logo slider
@mixin logo-slider($var-name, $animation-name, $height) {
  .logo-slider {
    @keyframes running-line-#{$animation-name} {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(calc(-1 * var(#{$var-name})));
      }
    }

    width: 100%;
    height: $height;
    overflow: hidden;
    position: relative;

    &__track {
      // track width is calculated by Smarty and being put in style attribute
      animation: running-line-#{$animation-name} 28s linear infinite;
      display: flex;
      align-items: center;
    }

    &__slide {
      margin: 0 20px;
      text-align: center;

      img {
        display: block;
      }
    }
  }
}

.trustpilot-reviews {
  display: flex;
  align-items: flex-end;
  height: 30px;

  svg {
    margin-left: 12px;
    align-self: center;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.team-profile {
  display: flex;
  gap: 16px;
  align-items: center;
  font-family: 'Inter';
  font-size: 16px;
  img {
    border-radius: 50%;
  }
  &__linkedin-inline {
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
    left: 12px;
  }
  a {
    color: inherit;
  }
}

.article-meeting-cta {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  @media (min-width: 837px) {
    flex-direction: row;
    justify-content: space-between;
  }
  &__link {
    color: #1F69FE;
    text-decoration: underline;
    font-weight: 500;
    text-underline-offset: 4px;
    cursor: pointer;
  }
  &__text {
    font-weight: 400;
  }
}

.small-btn-glow {
  position: relative;
  z-index: 1;
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 192px);
    border-radius: 383px;
    width: 383px;
    height: 80px;
    background: var(--btn-glow);
    filter: blur(74px);
    z-index: -1;
  }
}
